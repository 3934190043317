import {UserRole} from './UserRole';

export class User {
    gender = '';
    firstName = '';
    lastName = '';
    birthDate = '';
    cin = '';
    email = '';
    phone = '';
    gsm = '' ;
    adress = '';
    goverment = '';
    city = '';
    zipCode = '';
    soldeInit = '';
    cardNumber = '';
    quality = '';
    folderNumber = '';
    userName = '';
    publicId = '';
    password = '';
    solde = 0;
    soldeCompany: any = null;
    status: boolean = null;
    selected = false;
    actif = null;
    dateInscription = '';
    profileImage = '';
    rib = '';
    ribKey = '';
    roles: UserRole[] = [];
    type = '';
    signLink: string = "";
    contribution?: string;
    userArabInfo: UserArabInfo = new UserArabInfo();
    qualityInfo: UserQualityInfo = new UserQualityInfo();
    disciplinaryOrderStatus: string;
}


export class UserArabInfo{
    firstName   :string;
    lastName    :string;
    quality     :string;
    adress      :string;
    publicId    :string;
}

export class UserQualityInfo{
    dateDefinitive          :string;
    dateMatriculeFiscale    :string;
    dateReclassementDossier :string;
    folderStatus            :string;
    matriculeFiscale        :string;
    publicId                :string;
}