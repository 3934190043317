export const locale = {
    lang: 'en',
    data: {
        'INTERCEPTOR': {
            "ERROR":"A problem that arose while processing your request",
            "ERRORTEXT":"please try again later",
            "CLOSE":"Report and close",
            "REPORTPROBLEM":"Report the problem",
            "DESCRIPTIONPROBLEM":"Écrire la description ici..",
            "EXPIREDSESSION": "Session expired",
            "RECONNECT": "Reconnect"
        },
      
    }
};
