export const locale = {
    lang: 'fr',
    data: {
        'INTERCEPTOR': {
            "ERROR":"Un problème est survenu lors du traitement de votre demande",
            "ERRORTEXT":"veuillez réessayer plus tard",
            "CLOSE":"Reporter et fermer",
            "REPORTPROBLEM":"Reporter le problème",
            "DESCRIPTIONPROBLEM":"Écrire la description ici..",
            "EXPIREDSESSION": "Session expiré",
            "RECONNECT": "Réconnectez-vous"
        },
    }
};
