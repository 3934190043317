export const locale = {
    lang: 'en',
    data: {
        'NAVBAR': {
            'COTISATION': 'Contribution',
            'LOGOUT': 'Logout',
            'UPTODATE': 'Up to date',
            'NOTUPTODATE': 'Not up to date',
            'MAIL': 'New email',
            "OPERATIONCANCELED": "Operation canceled",
            "CLOSE": "Close",
            "NEWCOTISATION": "New contribution to pay, Amount",
            "NONOTIFICATIONS": "No notifications",
            "AMOUNT":"Amount",
        }
    }
};
