import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { HorizontalLayout1Module } from 'app/layout/horizontal/layout-1/layout-1.module';
import { VerticalLayout1Module } from 'app/layout/vertical/layout-1/layout-1.module';
import { VerticalLayout2Module } from 'app/layout/vertical/layout-2/layout-2.module';
import { VerticalLayout3Module } from 'app/layout/vertical/layout-3/layout-3.module';

import { MaintenancePageComponent } from './components/maintenance-page/maintenance-page.component';
import { InterceptorService } from './loader/interceptor.service';
import { SharedListUsersComponent } from './components/shared-list-users/shared-list-users.component';
import { ConfirmActionDialogComponent } from './components/confirm-action-dialog/confirm-action-dialog.component';
import { MatDialogModule } from '@angular/material/dialog';

@NgModule({
    imports: [
        VerticalLayout1Module,
        VerticalLayout2Module,
        VerticalLayout3Module,

        HorizontalLayout1Module,
        MatButtonModule,
        MatDialogModule
    ],
    exports: [
        VerticalLayout1Module,
        VerticalLayout2Module,
        VerticalLayout3Module,

        HorizontalLayout1Module
    ],
    providers: [
         {provide: HTTP_INTERCEPTORS, useClass: InterceptorService, multi: true}
    ],
    declarations: [MaintenancePageComponent, ConfirmActionDialogComponent]
})
export class LayoutModule
{
}
