import { Observable } from 'rxjs';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { environment } from './../../../../../../environments/environment';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {

    protected api_url = environment.api_url;

    constructor(private _httpClient: HttpClient) {
    }

    /**
     * get liste de tout les notification par id utilisateur
     * @param publicId
     */
     GetListNotifications(publicId: string): Observable<any> {
        const queryParameters = new HttpParams();
        const headers = new HttpHeaders();

        return this._httpClient.request<any>('get', `${this.api_url}/api/config/notification/all/byUser/${publicId}`,
            {
                headers: headers,
            }
        );
    }

    /**
     * Permettant de changer le statut de notification
     * @param publicId
     */
    changeNotificationStatus(publicId: string): Observable<any> {
        const queryParameters = new HttpParams();
        const headers = new HttpHeaders();

        return this._httpClient.request<any>('put', `${this.api_url}/api/config/notification/status/${publicId}`,
            {
                headers: headers,
            }
        );
    }
}
