import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatMenuModule } from '@angular/material/menu';
import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {CommonModule} from '@angular/common';

import {FlexLayoutModule} from '@angular/flex-layout';
import {NgxMaterialTimepickerModule} from 'ngx-material-timepicker';

import {FuseDirectivesModule} from '@fuse/directives/directives';
import {FusePipesModule} from '@fuse/pipes/pipes.module';
import {MatDialogModule} from '@angular/material/dialog';
import {TranslateModule} from '@ngx-translate/core';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatIconModule} from '@angular/material/icon';
import {MatButtonModule} from '@angular/material/button';
import {MatDividerModule} from '@angular/material/divider';
import {MatInputModule} from '@angular/material/input';
import {MatTableModule} from '@angular/material/table';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatRadioModule} from '@angular/material/radio';
import {MatPaginatorModule} from '@angular/material/paginator';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {MatSelectModule} from '@angular/material/select';
import {Ng2TelInputModule} from 'ng2-tel-input';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatExpansionModule} from '@angular/material/expansion';
import {DownloadFileDirective} from '../app/main/profile/_helpers/download-file.directive';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {ImageCropperModule} from 'ngx-image-cropper';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import {MatTabsModule} from '@angular/material/tabs';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatStepperModule } from '@angular/material/stepper';
import { SharedListUsersComponent } from 'app/layout/components/shared-list-users/shared-list-users.component';
import { MatSelectCountryModule } from '@angular-material-extensions/select-country';

@NgModule({
    declarations: [
        DownloadFileDirective,
        SharedListUsersComponent
    ],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,

        FlexLayoutModule,

        FuseDirectivesModule,
        FusePipesModule,

        MatDialogModule,
        TranslateModule,
        MatFormFieldModule,
        MatIconModule,
        MatButtonModule,
        MatDividerModule,
        MatIconModule,
        MatInputModule,
        MatTableModule,
        MatCheckboxModule,
        MatRadioModule,
        MatPaginatorModule,
        MatSnackBarModule,
        NgxMaterialTimepickerModule,
        MatSelectModule,
        MatDatepickerModule,
        MatExpansionModule,
        Ng2TelInputModule,
        MatProgressSpinnerModule,
        MatProgressBarModule,
        CKEditorModule,
        MatTabsModule,
        MatStepperModule,
        MatAutocompleteModule,
        MatSelectCountryModule.forRoot('fr'),
    ],
    exports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,

        FlexLayoutModule,

        FuseDirectivesModule,
        FusePipesModule,

        MatDialogModule,
        TranslateModule,
        MatFormFieldModule,
        MatIconModule,
        MatButtonModule,
        MatDividerModule,
        MatIconModule,
        MatInputModule,
        MatTableModule,
        MatCheckboxModule,
        MatRadioModule,
        MatPaginatorModule,
        MatSnackBarModule,
        NgxMaterialTimepickerModule,
        MatSelectModule,
        MatDatepickerModule,
        MatExpansionModule,

        Ng2TelInputModule,
        ImageCropperModule,
        MatProgressSpinnerModule,
        MatProgressBarModule,

        // Directive
        DownloadFileDirective,
        CKEditorModule,
        MatTabsModule,
        MatMenuModule,
        MatStepperModule,
        MatAutocompleteModule,
        SharedListUsersComponent,
        MatSelectCountryModule

    ]
})
export class FuseSharedModule {
}
