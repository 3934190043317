import {Injectable} from '@angular/core';
import {HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpResponse} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '../../../../environments/environment';
import {tap} from 'rxjs/operators';
import {FuseSplashScreenService} from '../../../../@fuse/services/splash-screen.service';


@Injectable()
export class JwtInterceptor implements HttpInterceptor {
    constructor(private splashScreenService: FuseSplashScreenService) {
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // add auth header with jwt if user is logged in and request is to api url
        const token = localStorage.getItem('token');
        const isApiUrl = request.url.startsWith(environment.api_url);
        if (isApiUrl && token != null) {
            request = request.clone({
                setHeaders: {
                    Authorization: `Bearer ${token}`
                }
            });
        }
        return next.handle(request);
    }
}
