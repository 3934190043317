import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class SharedMenuObserverService {

    currentMenuPosition = '';
    currentMenuPositionChange = new BehaviorSubject<string>('');

    currentIconMenu = '';
    currentIconMenuChange = new BehaviorSubject<string>('');

    cotisationStatusChange = new BehaviorSubject<boolean>(null);


    folderStatusUserChange = new BehaviorSubject<string>(null);




    updateMenu(currentPos: string): void {
        this.currentMenuPosition = currentPos;
        this.currentMenuPositionChange.next(currentPos);
    }

    updateIconMenu(icon: string): void {
        this.currentIconMenu = icon;
        this.currentIconMenuChange.next(icon);
    }

    onUpdateMenu(): Observable<any> {
        return this.currentMenuPositionChange.asObservable();
    }

    onUpdateIconMenu(): Observable<any> {
        return this.currentIconMenuChange.asObservable();
    }

    changeCotisationStatus(status: boolean):void {
        if(status != null)
        this.cotisationStatusChange.next(status)
    }

    onCotisationStatus(): Observable<any> {
        return this.cotisationStatusChange.asObservable();
    }

    onChangeFolderStatusUser(): Observable<any> {
        return this.folderStatusUserChange.asObservable();
    }

    constructor() {
    }
}
