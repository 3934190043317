import { environment } from './environments/environment';
import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppModule } from './app/app.module';
import * as Sentry from '@sentry/browser';

if ( environment.production && !environment.api_url.includes('localhost') )
{
    enableProdMode();

    Sentry.init({
        environment: 'production',
        tracesSampleRate: 1.0,
        dsn: 'https://71efea0dd2be4a980006f1a006e74040@sentry.neopolis-dev.com/14',
        tracePropagationTargets: [environment.api_url],
        release: environment.version
    });
}

platformBrowserDynamic().bootstrapModule(AppModule)
                        .catch(err => console.error(err));
