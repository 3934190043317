import { Component } from '@angular/core';
import { environment } from 'environments/environment';

@Component({
    selector   : 'footer',
    templateUrl: './footer.component.html',
    styleUrls  : ['./footer.component.scss']
})
export class FooterComponent
{
    appVersion = "V"+environment.version;
    buildDate = environment.build;
    /**
     * Constructor
     */
    constructor()
    {
    }
}
