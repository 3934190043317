<mat-toolbar class="footer-height">

    <div fxLayout="row" fxLayoutAlign="center center" fxLayoutAlign.gt-xs="space-between center" fxFlex>

        <!-- <a href="https://1.envato.market/c/1257954/275988/4415?u=https%3A%2F%2Fthemeforest.net%2Fitem%2Ffuse-angularjs-material-design-admin-template%2F12931855"
           target="_blank" mat-button class="pink" fxFlex="0 0 auto" fxLayout="row"
           fxLayoutAlign="start center">
            <mat-icon class="s-16 mr-sm-4">shopping_cart</mat-icon>
            <span>Purchase FUSE Angular</span>
        </a> -->
        <div></div>


        <div fxLayout="row" fxLayoutAlign="start center" fxHide fxShow.gt-xs>
            <small>Neopolis développement</small>
            <span>&nbsp;&copy;&nbsp;</span>
            <small>2024</small>
            <span>&nbsp;&middot;&nbsp;</span>
            <small>{{appVersion}}</small>
            <span>&nbsp;</span>
            <small>{{buildDate}}</small>
        </div>

    </div>

</mat-toolbar>