import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { environment } from '../../../../../environments/environment';
import { User } from '../../../_models/User';
import { Filter } from '../_models/filter';
import { DisciplinaryOrder } from '../_models/DisciplinaryOrder';

@Injectable({
    providedIn: 'root'
})
export class UserService {
    protected api_url = environment.api_url;

    /**
     * Constructor
     *
     * @param {HttpClient} httpClient
     */
    constructor(protected httpClient: HttpClient) {

    }

    /**
     * Permettant de retourner les informations details de lutilisateur courant
     * @param page numéro de page
     * @param size nombre des élements
     * @param filter filtre
     */
    GetListUser(page: number, size: number, filter: Filter): Observable<any> {

        let queryParameters = new HttpParams();
        if (page !== undefined && page !== null) {
            queryParameters = queryParameters.set('page', <any>page);
        }
        if (size !== undefined && size !== null) {
            queryParameters = queryParameters.set('size', <any>size);
        }
        if (filter?.ville !== undefined && filter?.ville != null && filter?.ville.length > 0) {
            filter.ville.forEach((ville: string) => {
                queryParameters = queryParameters.append(`goverments[]`, ville);
            });
        }

        if (filter?.cardNumber !== undefined && filter?.cardNumber !== null) {
            queryParameters = queryParameters.set('cardNumber', <any> filter.cardNumber);
        }

        if (filter?.firstName !== undefined && filter?.firstName !== null) {
            queryParameters = queryParameters.set('firstName', <any> filter.firstName);
        }
        if (filter?.lastName !== undefined && filter?.lastName !== null) {
            queryParameters = queryParameters.set('lastName', <any> filter.lastName);
        }
        if (filter?.cin !== undefined && filter?.cin !== null) {
            queryParameters = queryParameters.set('cin', <any> filter.cin);
        }
        if (filter?.email !== undefined && filter?.email !== null) {
            queryParameters = queryParameters.set('email', <any> filter.email);
        }
        if (filter?.status !== undefined && filter?.status !== null  && filter?.status.trim() !== '') {
            queryParameters = queryParameters.set('actif', <any> filter.status);
        }
        if (filter?.phone !== undefined && filter?.phone !== null) {
            queryParameters = queryParameters.set('phone', <any> filter.phone);
        }
        if (filter?.date_inscription_start !== undefined && filter?.date_inscription_start !== null) {
            queryParameters = queryParameters.set('date_inscription_start', <any> filter.date_inscription_start);
        }
        if (filter?.date_inscription_end !== undefined && filter?.date_inscription_end !== null) {
            queryParameters = queryParameters.set('date_inscription_end', <any> filter.date_inscription_end);
        }
        if (filter?.contribution !== undefined && filter?.contribution !== null && filter?.contribution.trim() !== '') {
            queryParameters = queryParameters.set('contribution', <any> filter.contribution);
        }
        if (filter?.gender !== undefined && filter?.gender !== null) {
            queryParameters = queryParameters.set('gender', <any> filter.gender);
        }
        if (filter?.folderNumber !== undefined && filter?.folderNumber !== null) {
            queryParameters = queryParameters.set('folderNumber', <any> filter.folderNumber);
        }
        if (filter?.folderStatus !== undefined && filter?.folderStatus !== null) {
            queryParameters = queryParameters.set('folderStatus', <any> filter.folderStatus);
        }
        if (filter?.quality !== undefined && filter?.quality !== null) {
            queryParameters = queryParameters.set('quality', <any> filter.quality);
        }

        const headers = new HttpHeaders();

        return this.httpClient.request<any>('get', `${this.api_url}/api/user/admin/list`,
            {
                params: queryParameters,
                headers: headers,
            }
        );
    }

    /**
     * Permettant de retourner les informations details de lutilisateur courant
     */
    GetUserdetails(): Observable<any> {
        const queryParameters = new HttpParams();
        const headers = new HttpHeaders();

        return this.httpClient.request<any>('get', `${this.api_url}/api/user/details`,
            {
                headers: headers,
            }
        );
    }

    /**
     * Permettant de retourner les informations details de lutilisateur courant
     *
     * @param userId
     *
     */
    GetUserById(userId: string): Observable<any> {
        const queryParameters = new HttpParams();
        const headers = new HttpHeaders();

        return this.httpClient.request<any>('get', `${this.api_url}/api/user/admin/details/${userId}`,
            {
                headers: headers,
            }
        );
    }

    /**
     * Permettant de modifier les informations  details utilisateur
     *
     * @param userId
     * @param user information utilisateur
     * @param passowrd information utilisateur
     *
     */
    UpdateUser(userId: string, user: User, passowrd: string): Observable<any> {
        let headers = new HttpHeaders();
        if(passowrd)
        headers = headers.append('password', passowrd);
        return this.httpClient.request<any>('put', `${this.api_url}/api/user/admin/update/${userId}`,
            {
                headers: headers,
                body: user
            }
        );
    }

    /**
     * Permettant de modifier les informations details utilisateur
     *
     * @param user information utilisateur
     *
     */
    CreateUser(user: User): Observable<any> {
        const queryParameters = new HttpParams();
        const headers = new HttpHeaders();

        return this.httpClient.request<any>('post', `${this.api_url}/api/user/add`,
            {
                headers: headers,
                body: user
            }
        );
    }

    /**
     * Permet de retourner la liste des utilisateurs membres a une entreprise
     * @param companyPublicId
     */
     GetListCompanyMembers(companyPublicId: string): Observable<any> {
        const queryParameters = new HttpParams();
        const headers = new HttpHeaders();

        return this.httpClient.request<any>('get', `${this.api_url}/api/user/companyMember/${companyPublicId}`,
            {
                headers: headers,
            }
        );
    }

    /**
     * Permettant de retourner les informations details de lutilisateur courant
     */
     ImportDataBaseFile(formaData): Observable<any> {
        const queryParameters = new HttpParams();
        const headers = new HttpHeaders();
        headers.set('responseType', 'blob');
        return this.httpClient.request<any>('POST', `${this.api_url}/api/user/readFromCSV`,
            {
                headers: headers,
                body: formaData
            })
        }


        validateDataBaseFile(formaData): Observable<any> {
            const queryParameters = new HttpParams();
            const headers = new HttpHeaders();
            headers.set('responseType', 'blob');
            return this.httpClient.request<any>('POST', `${this.api_url}/api/user/readFromCSV`,
                {
                    headers: headers,
                    body: formaData
                })
            }
    /**
     * Permet de changer le rôle d'un utilisateur
     * @param userId
     * @param role
     */
     SetRoleMember(userId: string, role: string): Observable<any> {
        let queryParameters = new HttpParams();

        queryParameters = queryParameters.set('role',role)

        const headers = new HttpHeaders();

        return this.httpClient.request<any>('put', `${this.api_url}/api/super/user/setRole/${userId}`,
            {
                headers: headers,
                params: queryParameters
            }
        )
     }

    /**
     * Permet d'activer ou déactiver un utilisteurs
     * @param publicId
     */
     ChangeUserStatus(publicId: string, status:boolean): Observable<any> {
        let queryParameters = new HttpParams();
        if (status !== undefined && status!== null) {
            queryParameters = queryParameters.set('status', <any> status);
        }
        const headers = new HttpHeaders();

        return this.httpClient.request<any>('post', `${this.api_url}/api/user/changeStatus/${publicId}`,
            {
                headers: headers,
                params: queryParameters
            }
        );
    }
    
    /* Permet d'exporter list des utilisateurs en .excel
     * @param filter
     */
    ExportCSV(filter: Filter, size:number): Observable<any> {
        let queryParameters = new HttpParams();

        queryParameters = queryParameters.set('size', <any> size);

        if (filter?.ville !== undefined && filter?.ville != null && filter?.ville.length > 0) {
            filter.ville.forEach((ville: string) => {
                queryParameters = queryParameters.append(`goverments[]`, ville);
            });
        }

        if (filter?.cardNumber !== undefined && filter?.cardNumber !== null) {
            queryParameters = queryParameters.set('cardNumber', <any> filter.cardNumber);
        }

        if (filter?.firstName !== undefined && filter?.firstName !== null) {
            queryParameters = queryParameters.set('firstName', <any> filter.firstName);
        }
        if (filter?.lastName !== undefined && filter?.lastName !== null) {
            queryParameters = queryParameters.set('lastName', <any> filter.lastName);
        }
        if (filter?.cin !== undefined && filter?.cin !== null) {
            queryParameters = queryParameters.set('cin', <any> filter.cin);
        }
        if (filter?.email !== undefined && filter?.email !== null) {
            queryParameters = queryParameters.set('email', <any> filter.email);
        }
        if (filter?.status !== undefined && filter?.status !== null  && filter?.status.trim() !== '') {
            queryParameters = queryParameters.set('actif', <any> filter.status);
        }
        if (filter?.phone !== undefined && filter?.phone !== null) {
            queryParameters = queryParameters.set('phone', <any> filter.phone);
        }
        if (filter?.date_inscription_start !== undefined && filter?.date_inscription_start !== null) {
            queryParameters = queryParameters.set('date_inscription_start', <any> filter.date_inscription_start);
        }
        if (filter?.date_inscription_end !== undefined && filter?.date_inscription_end !== null) {
            queryParameters = queryParameters.set('date_inscription_end', <any> filter.date_inscription_end);
        }
        if (filter?.contribution !== undefined && filter?.contribution !== null && filter?.contribution.trim() !== '') {
            queryParameters = queryParameters.set('contribution', <any> filter.contribution);
        }
        if (filter?.gender !== undefined && filter?.gender !== null) {
            queryParameters = queryParameters.set('gender', <any> filter.gender);
        }
        const headers = new HttpHeaders();
        return this.httpClient.request<any>('get', `${this.api_url}/api/user/export`,
            {
                params: queryParameters,
                headers: headers,
            }
        );
    }

    convertDate(list:User[]): Observable<any> {
        let queryParameters = new HttpParams();

        const headers = new HttpHeaders();

        return this.httpClient.request<any>('put', `${this.api_url}/api/user/format`,
            {
                headers: headers,
                body: list
            }
        );
    }

    /**
     * Permettant de ajouter une cotisation pour un utilisateur
     *
     * @param publicId id utilisateur
     *
     */
     addCotisationForUser(publicId: string): Observable<any> {
        const queryParameters = new HttpParams();
        const headers = new HttpHeaders();

        return this.httpClient.request<any>('post', `${this.api_url}/api/user/add/cotisation/afterInsert/${publicId}`,
            {
                headers: headers,
            }
        );
    }

    /**
     * Permettant de retourner les information de l'utilisateur par n° carte pro
     *
     * @param cardNumber id utilisateur
     *
     */
    GetUserByCardNumber(cardNumber: string): Observable<any> {
        let queryParameters = new HttpParams();
        const headers = new HttpHeaders();

        return this.httpClient.request<any>('get', `${this.api_url}/api/user/getUserByCardNumber/${cardNumber}`,
            {
                headers: headers,
            }
        );
    }
}
