import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { finalize, tap, catchError } from 'rxjs/operators';
import { LoaderService } from './loader.service';

@Injectable({
  providedIn: 'root'
})
export class InterceptorService implements HttpInterceptor{

  constructor(public _loaderService: LoaderService) { }
  
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if(!req.url.includes('searchByEmail'))
    this._loaderService.isLoading.next(true);
    /*
    const t0 = performance.now();
    let interval;

    interval = setInterval(() => {
      const t1 = performance.now();
      const responseTime = (t1 - t0) / 1000;
      console.log(`Request took ${responseTime} ms since start`);
    }, 10);*/
    return next.handle(req).pipe(
       /* tap((ev: HttpEvent<any>) => {
            if (ev instanceof HttpResponse) {
              console.log('processing response', ev);
            }
          }),
          catchError((response) => {
            if (response instanceof HttpErrorResponse) {
              console.log('processing http error', response);
            }
    
            return Observable.throw(response);
          }),*/
      finalize(
        () => {
            this._loaderService.isLoading.next(false);
            /*const t1 = performance.now();
            const totalResponseTime = (t1 - t0) / 1000;
            console.log(`Request finished: took ${totalResponseTime} ms`);
    
            clearInterval(interval);*/
        }
      )
    );
  }
}
