import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';
import { Mail } from '../Model/Mail';

@Injectable({
  providedIn: 'root'
})
export class MailService {

  protected api_url = environment.api_url;

  /**
   * Constructor
   *
   * @param {HttpClient} httpClient
   */
  constructor(protected httpClient: HttpClient) {}

    /**
     * Permet d'envoyer des emails
     */
   SendMails(mail:Mail, html: boolean): Observable<any> {
    let queryParameters = new HttpParams();

        queryParameters = queryParameters.set('subject', <any>mail.subject);
        queryParameters = queryParameters.set('mailContent', <any>mail.mailContent);
        queryParameters = queryParameters.set('html', <any>html);
    const headers = new HttpHeaders();

    return this.httpClient.request<any>('post', `${this.api_url}/api/sendEmail/new`,
        {
          params: queryParameters,
          headers: headers,
          body: mail.usersPublicIds
        }
    );
}

}
