export const locale = {
    lang: 'fr',
    data: {
        'MAIL': {
            'TITLE': 'Nouvel email',
        },
        'SELECTEVENT': {
            'TITLENOTINVITE': 'Liste des utilisateurs non invités à cet évènement',
            'TITLEINVITE': 'Liste des utilisateurs invités à cet évènement',
            'INVITE': 'Inviter',
            'NINSCRIT': 'N° Inscription	',
            'NOM': 'Nom',
            'PRENOM': 'Prénom',
            'CIN': 'CIN',
            'EMAIL': 'Email',
            'GOVERNORAT': 'Gouvernorat',
            'SOLDE': 'Solde',
            'STATUS': 'Statut',
            'TELEPHONE': 'Téléphone',
            'GENRE': 'Genre',
            'TOUT': 'Tout',
            'COMPTE': 'Compte',
            'ACTIVE': 'Activé',
            'DEACTIVE': 'Non activé',
            'AJOUR': 'A jour',
            'NOAJOUR': 'Pas à jour',
            'FILTER': 'Filtrer',
            'INSCRITDU': 'Date Inscription du',
            'TO': 'à',
            'CONTRIBUTION': 'Cotisation',
            'NODATA': 'Aucune donnée correspondant',
            "SOMETHINGWENTWRONG": "Quelque chose s'est mal passé!",
            "CLOSE": "Fermer",
            "OPERATIONSUCCESS": 'Opération Réussite!',
            "OPERATIONCANCELED": "Opération de suppression de cet utilisateur a été annulée",
            "DELETIONSUCCESS": "Suppression efféctuée avec succés",
            "CHOOSEONEUSER": "Choisir au moins un utilisateur",
            "INVITATIONSENT": "Invitation envoyé",
            "DOYOUWANTTOCANCEL": "Voulez vous vraiment annuler l'invitation pour cet utilisateur?",
            "NOKEEPIT": "Non, garder le",
            "YESDELETE": "Oui, supprimer",
            "MALE": 'Homme',
            "FEMALE": 'Femme',
            'SUBJECT':'Sujet',
            'ENVOYE':'Envoyer',
            'NEWMESSAGE':'Nouveau message',
            'EMAILTOWHO':'Envoyer à',
            'EMAILSENDED':'Votre email a été envoyée avec succès',
            'EMAILERROR':'Erreur lors de l\'envoi de votre email',
            'ERROR':'Erreur',
            'EMAILWARNING':'Il faut respecter les champs obligatoires',
            "INVTITEDNBRE": 'Utilisateur(s)',
            "LISTUSERERROR":"Erreur lors du chargement de la liste des adhérents"
        },
    }
};
