export class Filter {
    gender = null;
    cardNumber = null;
    firstName = null;
    lastName = null;
    cin = null;
    email = null;
    solde = null;
    status = null;
    stat = null;
    sold = null;
    ville = null;
    statusN = null;
    soldeN = null;
    dateInscription = null;
    phone = null;
    date_inscription_start = null;
    date_inscription_end = null;
    contribution = null;
    folderNumber = null;
    folderStatus: string;
    quality: string;
}
