export const locale = {
    lang: 'fr',
    data: {
        'NAV': {
            'ADMIN': 'Administrateur',
            'USER': 'Utilisateur',
            'ADHERANT': 'Adhérant',
            'EXPORTASSURANCE': 'Liste des contrats d\'assurance',
            'GESTIONEVENT': 'Gestion des événements',
            'LISTEVENT': 'Liste des événements',
            'GESTIONUSER': 'Gestion des utilisateurs',
            'GESTIONENTREPRISE': 'Gestion des sociétés',
            'PROFILE': 'Mon profil',
            'LISTPAIEMENT': 'Liste des paiements',
            'COTISATION': 'Ma cotisation',
            'GESTIONCONVENTION': 'Conventions et documents partagés',
            'LISTCONVENTION': 'Conventions et documents partagés',
            'ASSURANCE': 'Assurance',
            'ACTUALITE': 'Fil d\'actualités',
            'TRACABILITY': 'Traçabilités',
            'GESTIONCOTISATION': 'Gestion des cotisations',
            'LISTTRAINEE': "Liste des stagiaires",
            'LISTREQUEST': "Liste des demandes",
            'TRAINEE': 'Stagiaires'
        }
    }
};
