import { environment } from 'environments/environment';
import {Inject, Injectable, Optional} from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from "@angular/common/http";

import {Observable} from "rxjs";
import { IssueGitlab } from './_models/IssueGitlab';
@Injectable({
  providedIn: 'root'
})
export class GitlabService {
    protected basePath = environment.api_url;
    protected gitlabPath = 'https://git.interface-crm.com/api/v4/projects/97';
  
    constructor(protected httpClient: HttpClient) {}
  
    public pushIssue(issue:IssueGitlab, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
  
      if (issue === null || issue === undefined) {
        throw new Error('Required parameter IssueGitlab was null or undefined when calling rechercheLexiqueGet.');
      }
  
      let queryParameters = new HttpParams();
      if (issue.title !== undefined && issue.title !== null) {
        queryParameters = queryParameters.set('title', <any>issue.nom+': '+issue.title);
      }
      if (issue.labels !== undefined && issue.labels !== null) {
        queryParameters = queryParameters.set('labels', <any>issue.labels);
      }
  
      if (issue.description !== undefined && issue.description !== null) {
        queryParameters = queryParameters.set('description', <any>issue.description);
      }
  
      let headers = new HttpHeaders();
  
        // authentication (Acess_Token) required
        headers = headers.set('PRIVATE-TOKEN', 'zzkJsgpRfrnPwxQS_sC3');
      
  
      return this.httpClient.request<any>('post',`${this.gitlabPath}/issues`,
        {
          params: queryParameters,
          headers: headers,
          observe: observe,
          reportProgress: reportProgress
        }
      );
    }

    public closeIssue(issue:number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
  
        if (issue === null || issue === undefined) {
          throw new Error('Required parameter IssueGitlab was null or undefined when calling rechercheLexiqueGet.');
        }

        let queryParameters = new HttpParams();
        queryParameters = queryParameters.set('state_event', 'close');
        
        
        let headers = new HttpHeaders();
    
          // authentication (Acess_Token) required
          headers = headers.set('PRIVATE-TOKEN', 'zzkJsgpRfrnPwxQS_sC3');
        
    
        return this.httpClient.request<any>('put',`${this.gitlabPath}/issues/${issue}`,
          {
            params:queryParameters,
            headers: headers,
            observe: observe,
            reportProgress: reportProgress
          }
        );
      }

      public listIssue(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
  
    
        let queryParameters = new HttpParams();

    
        let headers = new HttpHeaders();
    
          // authentication (Acess_Token) required
          headers = headers.set('PRIVATE-TOKEN', 'zzkJsgpRfrnPwxQS_sC3');
        
    
        return this.httpClient.request<any>('get',`${this.gitlabPath}/issues`,
          {
            headers: headers,
            observe: observe,
            reportProgress: reportProgress
          }
        );
      }

}
