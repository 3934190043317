<mat-toolbar class="p-0 mat-elevation-z1">

    <div fxFlex fxFill fxLayout="row" fxLayoutAlign="start center">

        <div fxFlex="1 0 auto" fxLayout="row" fxLayoutAlign="start center">

            <button mat-icon-button class="navbar-toggle-button" *ngIf="!hiddenNavbar && !rightNavbar" (click)="toggleSidebarOpen('navbar')" fxHide.gt-md>
                <mat-icon class="secondary-text">menu</mat-icon>
            </button>

            <div class="toolbar-separator" *ngIf="!hiddenNavbar && !rightNavbar" fxHide.gt-md></div>

            <div fxLayout="row" fxLayoutAlign="start center" *ngIf="horizontalNavbar">
                <div class="logo ml-16">
                    <img *ngIf="user.profileImage == '' || user.profileImage == null" class="avatar mr-0 mr-sm-16" src="assets/images/avatars/profile.jpg">
                    <img *ngIf="user.profileImage != '' && user.profileImage != null" class="avatar mr-0 mr-sm-16" [src]="profileImage">
                </div>
            </div>

            <div class="px-8 px-md-16">
                <a *ngIf="!hasRole('super') && !hasRole('ROLE_TRAINEE')" href="{{cotisation_url}}" class="cotisation-label" style="color: inherit;">
                    <p style="font-size: 12px; border-radius: 5px; padding: 0 5px">
                        <span style="margin-right: 5px" class="cotisation-navbar">{{ 'NAVBAR.COTISATION' | translate }}: </span>

                        <span *ngIf="cotisation" style="background-color: #06EA11; padding: 2px 5px; border-radius: 5px; color: white"> {{ 'NAVBAR.UPTODATE' | translate }}</span>
                        <span *ngIf="cotisation == false" style="background-color: #ff3232; padding: 2px 5px; border-radius: 5px; color: white">
                              <span class="wrap">
                                <span class="icon-warning-animate">
                                    {{ 'NAVBAR.NOTUPTODATE' | translate }}
                                </span>
                        </span>

                        </span>
                    </p>
                </a>
                <!--<fuse-shortcuts [navigation]="navigation"></fuse-shortcuts>-->
            </div>
        </div>

        <div class="" fxFlex="0 1 auto" fxLayout="row" fxLayoutAlign="start between">

            <button class="mail-button" type="button" routerLink="/profile/certificat" title="Carte professionnelle & Attestations">
                <span class="material-icons" matTooltip="Carte & attestations">book</span>
            </button>

            <button class="mail-button" type="button" [matMenuTriggerFor]="menu">
                <span class="material-icons">notifications</span>
                <span class="icon-button__badge">{{notifications?.length}}</span>
            </button>
            <mat-menu #menu="matMenu">
                <button mat-menu-item class="h-100-p" (click)="navigateTo(notification, index)" *ngFor="let notification of notifications; let index = index">
                    <div class="">
                        <div class="notification-picture-person">
                            <img *ngIf="notification?.user?.profileImage == '' || user.profileImage == null" class="avatar mr-0 mr-sm-16"
                            src="assets/images/avatars/profile.jpg" alt="">
                            <img *ngIf="notification?.user?.profileImage != '' && user.profileImage != null" class="avatar mr-0 mr-sm-16"
                                [src]="profileImage" alt="">
                            
                            <span class="material-icons notification-icon">notifications_active</span>
                            
                            <strong>{{notification?.title}} </strong>

                            <span *ngIf="notification.status == 'NOTCONSULTED'" class="material-icons notification-visible">fiber_new</span>
                        </div>
                        <div>
                            <p class="notification-items">
                                {{notification?.description}}
                            </p>
                            <p class="notification-items">
                                {{ 'NAVBAR.AMOUNT' | translate }}  : <span style="font-weight: bold;"> {{notification?.extraInfo}} DT </span> 
                            </p>
                            <p class="notification-items notification-date h-100-p">
                                <strong>{{calcDays(notification?.dateNotification)}}</strong>
                            </p>
                        </div>
                    </div>
                </button>
                <button mat-menu-item class="h-100-p" *ngIf="notifications.length == 0">
                    Pas de notifications
                </button>
            </mat-menu>

            <!-- <button *ngIf="!hasRole('super')" type="button" class="icon-button">
                <span class="material-icons">notifications</span>
                <span class="icon-button__badge">{{notifications?.length}}</span>

                <div class="notification-bar"  [ngClass]="{'notification-bar-fixed-height':notifications?.length >= 3 }">
                    <ng-container *ngIf="notifications?.length == 0">
                        <span class="no-notif">
                            {{ 'NAVBAR.NONOTIFICATIONS' | translate }}
                        </span>
                    </ng-container>
                    <ng-container *ngIf="notifications?.length > 0">
                        <div class="notification-items" (click)="navigateTo()" *ngFor="let notification of notifications">
                            <div class="notification-picture-person">
                                <img *ngIf="notification?.user?.profileImage == '' || user.profileImage == null" class="avatar mr-0 mr-sm-16"
                                src="assets/images/avatars/profile.jpg">
                                <img *ngIf="notification?.user?.profileImage != '' && user.profileImage != null" class="avatar mr-0 mr-sm-16"
                                    [src]="profileImage">
                            </div>
                            <div class="notification-content">
                                <div class="notif-date">
                                        {{notification?.dateNotification | date:'dd-MM-y,  h:mm '}}
                                </div>
                                <div class="notification-name-user">
                                    <span>
                                        {{notification?.title}} 
                                    </span>
                                </div>
                                <div class="notification-details">
                                    <p class="details-content">
                                        {{notification?.description}} 
                                    </p>
                                </div>
                                <div class="notification-details">
                                    <p class="details-content">
                                        {{ 'NAVBAR.AMOUNT' | translate }}  : <span style="font-weight: bold;"> {{notification?.extraInfo}} DT </span> 
                                     
                                    </p>
                                </div>
                            </div>
                            <div class="notification-actions">
                                <div class="notification-icon">
                                    <span class="material-icons">notifications_active</span>
                                </div>
                            </div>
                        </div>
                    </ng-container>
                </div>
            </button> -->

            <button *ngIf="hasRole('admin')" class="mail-button" (click)="composeMail()" data-title="{{ 'NAVBAR.MAIL' | translate }}">
                <div fxLayout="row" fxLayoutAlign="center center">
                    <span class="material-icons">email</span>
                    <span class="material-icons icon-button__mail">add</span>
                </div>
            </button>
            </div>
            <div class="" fxFlex="0 1 auto" fxLayout="row" fxLayoutAlign="start center">

            <button mat-button [matMenuTriggerFor]="userMenu" class="user-button">
                <div fxLayout="row" fxLayoutAlign="center center">
                    <img *ngIf="user.profileImage == '' || user.profileImage == null" class="avatar mr-0 mr-sm-16"
                        src="assets/images/avatars/profile.jpg">
                    <img *ngIf="user.profileImage != '' && user.profileImage != null" class="avatar mr-0 mr-sm-16"
                        [src]="profileImage">
                    <span class="username mr-12" fxHide fxShow.gt-sm>{{user?.firstName}} {{user?.lastName}}</span>
                    <mat-icon class="s-16" fxHide.xs>keyboard_arrow_down</mat-icon>
                </div>
            </button>

            <mat-menu #userMenu="matMenu" [overlapTrigger]="false">

                <!--<button mat-menu-item>
                    <mat-icon>account_circle</mat-icon>
                    <span>My Profile</span>
                </button>-->

                <!--<button mat-menu-item class="">
                    <mat-icon>mail</mat-icon>
                    <span>Inbox</span>
                </button>-->

                <button (click)="onLogout()" mat-menu-item class="">
                    <mat-icon>exit_to_app</mat-icon>
                    <span> {{ 'NAVBAR.LOGOUT' | translate }}</span>
                </button>

            </mat-menu>

            <div class="toolbar-separator"></div>

            <!-- <fuse-search-bar (input)="search($event)"></fuse-search-bar>-->

            <div class="toolbar-separator"></div>

            <button mat-button fxHide fxShow.gt-xs class="language-button" [matMenuTriggerFor]="languageMenu">
                <div fxLayout="row" fxLayoutAlign="center center">
                    <img class="flag mr-8" [src]="'assets/icons/flags/'+selectedLanguage.flag+'.png'">
                    <span class="iso text-uppercase">{{selectedLanguage.id}}</span>
                </div>
            </button>
            <mat-menu #languageMenu="matMenu" [overlapTrigger]="false">

                <button mat-menu-item *ngFor="let lang of languages" (click)="setLanguage(lang)">
                    <span fxLayout="row" fxLayoutAlign="start center">
                        <img class="flag mr-16" [src]="'assets/icons/flags/'+lang.flag+'.png'">
                        <span class="iso">{{lang.title}}</span>
                    </span>
                </button>

            </mat-menu>

            <div class="toolbar-separator" fxHide fxShow.gt-xs></div>

            <!--<button mat-icon-button
                    class="quick-panel-toggle-button"
                    (click)="toggleSidebarOpen('quickPanel')"
                    aria-label="Toggle quick panel">
                <mat-icon class="secondary-text">format_list_bulleted</mat-icon>
            </button>-->

            <div class="toolbar-separator" *ngIf="!hiddenNavbar && rightNavbar" fxHide fxShow.gt-xs></div>

            <button mat-icon-button class="navbar-toggle-button" *ngIf="!hiddenNavbar && rightNavbar" (click)="toggleSidebarOpen('navbar')" fxHide.gt-md>
                <mat-icon class="secondary-text">menu</mat-icon>
            </button>

        </div>



    </div>

</mat-toolbar>