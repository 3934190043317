export const environment = {
    production: true,
    hmr       : false,
    api_url: 'https://cct-ws.neopolis-dev.com',
    recaptchaSiteKey: '6LfZH-wbAAAAAGO1fLWvStExn6IYO3cXg9xjztp9',
    SENTRY_AUTH_TOKEN: '502331f57af97f287f679d887b69145fab397241a0700ef7fc3613f8d5ffbe69',
    build: '23-07-2024-11-07-41',
    version: require('../../package.json').version

};
