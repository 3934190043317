import {ErrorHandler, LOCALE_ID, NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {RouterModule, Routes} from '@angular/router';
import {MatMomentDateModule} from '@angular/material-moment-adapter';
import {MatButtonModule} from '@angular/material/button';
import {MatIconModule} from '@angular/material/icon';
import {TranslateModule} from '@ngx-translate/core';

import {FuseModule} from '@fuse/fuse.module';
import {FuseSharedModule} from '@fuse/shared.module';
import {FuseProgressBarModule, FuseSidebarModule, FuseThemeOptionsModule} from '@fuse/components';

import {fuseConfig} from 'app/fuse-config';

import {AppComponent} from 'app/app.component';
import {LayoutModule} from 'app/layout/layout.module';
import {JwtInterceptor} from './main/profile/_helpers/jwt.interceptor';
import {ErrorInterceptor} from './main/profile/_helpers/error.interceptor';
import { SentryErrorHandler } from './sentry-error-handler';

const appRoutes: Routes = [
    {
        path: 'public',
        loadChildren: () => import('./main/public-components/public-components.module').then(m => m.PublicComponentsModule)
    },
    {
        path: 'auth',
        loadChildren: () => import('./main/authentication/authentication.module').then(m => m.AuthenticationModule)
    },
    {
        path: 'profile',
        loadChildren: () => import('./main/profile/profile.module').then(m => m.ProfileModule),
      //  canLoad: [AuthGuard],
    },
    {
        path: '**',
        redirectTo: 'profile'
    }
];

@NgModule({
    declarations: [
        AppComponent,
    ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        HttpClientModule,
        RouterModule.forRoot(appRoutes, {relativeLinkResolution: 'legacy'}),

        TranslateModule.forRoot(),

        // Material moment date module
        MatMomentDateModule,

        // Material
        MatButtonModule,
        MatIconModule,

        // Fuse modules
        FuseModule.forRoot(fuseConfig),
        FuseProgressBarModule,
        FuseSharedModule,
        FuseSidebarModule,
        FuseThemeOptionsModule,
        
        // App modules
        LayoutModule
    ],
    providers: [
        {provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true},
        {provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true},
        //{provide: HTTP_INTERCEPTORS, useClass: SessionRecoveryInterceptor, multi: true},
        { provide: ErrorHandler, useClass: SentryErrorHandler } 
    ],
    bootstrap: [
        AppComponent
    ]
})
export class AppModule {
}
