import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ErrorService {

    errorDescription: string = '';
    errorDescriptionChange = new BehaviorSubject<string>('');

  constructor() { }

    getErrorType(description: string): void {
        this.errorDescription = description;
        this.errorDescriptionChange.next(description);
    }

    onChangeErrorType(): Observable<any> {
        return this.errorDescriptionChange.asObservable();
    }

}
