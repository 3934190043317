export const locale = {
    lang: 'en',
    data: {
        'MAIL': {
            'TITLE': 'New email',
        },
        'SELECTEVENT': {
            'TITLENOTINVITE': 'List of users not invited to this meeting',
            'TITLEINVITE': 'List of users invited to this meeting',
            'INVITE': 'Invite',
            'NINSCRIT': 'N ° Registration',
            'NOM': 'First name',
            'PRENOM': 'Last name',
            'CIN': 'CIN',
            'EMAIL': 'Email',
            'GOVERNORAT': 'Governorate',
            'SOLDE': 'Balance',
            'STATUS': 'Status',
            'TELEPHONE': 'Phone',
            'GENRE': 'Gender',
            'TOUT': 'All',
            'COMPTE': 'Account',
            'ACTIVE': 'Activated',
            'DEACTIVE': 'Not activated',
            'AJOUR': 'Up to date',
            'NOAJOUR': 'Not up to date',
            'FILTER': 'Filter',
            'INSCRITDU': 'Registration date of',
            'TO': 'to',
            'CONTRIBUTION': 'Contribution',
            'NODATA': 'No matching data',
            "SOMETHINGWENTWRONG": "Something went wrong!",
            "CLOSE": "Close",
            "OPERATIONSUCCESS": 'Operation Success!',
            "OPERATIONCANCELED": "Operation to delete this user has been canceled",
            "DELETIONSUCCESS": "Successful deletion",
            "CHOOSEONEUSER": "Choose at least one user",
            "INVITATIONSENT": "Invitation sent",
            "DOYOUWANTTOCANCEL": "Do you really want to cancel the invitation for this user?",
            "NOKEEPIT": "No, keep it",
            "YESDELETE": "Yes, delete it",
            "MALE": 'Male',
            "FEMALE": 'Female',
            'SUBJECT':'Subject',
            'ENVOYE':'Send',
            'NEWMESSAGE':'New message',
            'EMAILTOWHO':'Send to',
            'EMAILSENDED':'your email has been sent successfully',
            'EMAILERROR':'Error sending your email',
            'ERROR':'Erreur',
            'EMAILWARNING':'You must respect the mandatory fields',
            "INVTITEDNBRE": 'User(s)',
            "LISTUSERERROR":"Error loading member list"
        },
    }
};
