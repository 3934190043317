export const locale = {
    lang: 'fr',
    data: {
        'NAVBAR': {
            'COTISATION': 'Cotisation',
            'LOGOUT': 'Déconnexion',
            'UPTODATE': 'À jour',
            'NOTUPTODATE': 'N\'est pas à jour',
            'MAIL': 'Nouvel email',
            "OPERATIONCANCELED": "Opération annulée",
            "CLOSE": "Fermer",
            "NEWCOTISATION": "Nouvelle cotisation a payer, Montant",
            "NONOTIFICATIONS": "Pas de notifications",
            "AMOUNT":"Montant",
        }
    }
};
