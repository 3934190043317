import { AfterViewInit, Component, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import * as _ from 'lodash';

import { locale as english } from '../i18n/en';
import { locale as french } from '../i18n/fr';
import { FuseConfigService } from '@fuse/services/config.service';
import { FuseSidebarService } from '@fuse/components/sidebar/sidebar.service';

import { navigation, navigationAdmin } from 'app/navigation/navigation';
import { FuseTranslationLoaderService } from '../../../../@fuse/services/translation-loader.service';
import { AuthenticationService } from '../../../main/authentication/_services/authentication.service';
import { Router } from '@angular/router';
import { User } from '../../../main/_models/User';
import { MatDialog } from '@angular/material/dialog';
import { SendMailComponent } from 'app/main/profile/send-mail/send-mail.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CotisationService } from 'app/main/profile/gestion-cotisation/_services/cotisation.service';
import { Cotisation } from 'app/main/profile/gestion-cotisation/_models/Cotisation';
import { Company } from 'app/main/profile/compagnies/_models/Company';
import { CompagnieService } from 'app/main/profile/compagnies/_services/compagnie.service';
import { Notification } from './Notification';
import { NotificationService } from './notification-services/notification-services/notification.service';
import { SharedMenuObserverService } from 'app/main/profile/_services/shared-menu-observer.service';
import { DisciplinaryOrder } from 'app/main/profile/users/_models/DisciplinaryOrder';

@Component({
    selector: 'toolbar',
    templateUrl: './toolbar.component.html',
    styleUrls: ['./toolbar.component.scss'],
    encapsulation: ViewEncapsulation.None
})

export class ToolbarComponent implements OnInit, OnDestroy, AfterViewInit {
    horizontalNavbar: boolean;
    rightNavbar: boolean;
    hiddenNavbar: boolean;
    languages: any;
    navigation: any;
    selectedLanguage: any;
    userStatusOptions: any[];
    cotisation_url: any = '/profile/cotisation';
    cotisation = null;
    user: User = new User();
    profileImage: string;
    notifications:Notification[]=[];
    cotisations: Cotisation[]=[]
    companyList: Company[] = [];


    // Private
    private _unsubscribeAll: Subject<any>;
    dialogRef: any;

    /**
     * Constructor
     *
     * @param {FuseConfigService} _fuseConfigService
     * @param {FuseSidebarService} _fuseSidebarService
     * @param {TranslateService} _translateService
     */
    constructor(
        private _fuseConfigService: FuseConfigService,
        private _fuseSidebarService: FuseSidebarService,
        private _translateService: TranslateService,
        private translationLoader: FuseTranslationLoaderService,
        private authenticationService: AuthenticationService,
        private router: Router,
        public _matDialog: MatDialog,
        public _snackBar: MatSnackBar,
        private notificationServices: NotificationService,
        private sharedMenuObserverService: SharedMenuObserverService
    ) {
        // Load translations
        this.translationLoader.loadTranslations(english, french);
        // Set the defaults
        this.userStatusOptions = [
            {
                title: 'Online',
                icon: 'icon-checkbox-marked-circle',
                color: '#4CAF50'
            },
            {
                title: 'Away',
                icon: 'icon-clock',
                color: '#FFC107'
            },
            {
                title: 'Do not Disturb',
                icon: 'icon-minus-circle',
                color: '#F44336'
            },
            {
                title: 'Invisible',
                icon: 'icon-checkbox-blank-circle-outline',
                color: '#BDBDBD'
            },
            {
                title: 'Offline',
                icon: 'icon-checkbox-blank-circle-outline',
                color: '#616161'
            }
        ];

        this.languages = [
            {
                id: 'fr',
                title: 'Français',
                flag: 'fr'
            },
            {
                id: 'en',
                title: 'English',
                flag: 'us'
            },
        ];

        this.navigation = navigation;

        // Set the private defaults
        this._unsubscribeAll = new Subject();

        if(!window.location.pathname.includes('/public'))
        this.authenticationService?.getUserDetails()?.subscribe((user: User) => {
            this.user = user;
            localStorage.setItem('currentUser', JSON.stringify(user))
            if(!this.hasRole('super')){
                this.getListNotifications()
            }
            this.profileImage = 'data:image/jpeg;base64,' + user.profileImage;
            this.cotisation = true
            if(user.solde < 0 && Object.keys(user.soldeCompany).length == 0){
                this.cotisation = false;
            }else if(user.solde != 0 && Object.keys(user.soldeCompany).length > 0){
                Object.keys(user.soldeCompany).forEach(key => {
                    const value = user.soldeCompany[key];
                    if (value < 0 || user.solde < 0) {
                        this.cotisation = false;
                    }
                });
            }
            if(user.disciplinaryOrderStatus == 'SUSPENSION')
            authenticationService.getDisciplinaryOrderByUser(user.publicId, 'user')
            .subscribe((response: DisciplinaryOrder)=>{
                if(new Date(response.suspensionStartDate) <= new Date() &&new Date() > new Date(response.suspensionEndDate) ){
                    this.onLogout()
                }
            })
            /*Object.keys(this.user.soldeCompany).forEach(key => {
                const value = this.user.soldeCompany[key];
                if (value < 0 || this.user.solde < 0) {
                    this.cotisation = false;
                }
            });*/
        },(error)=>{
            this.onLogout();
        });
    }
    ngAfterViewInit(): void {
        this.sharedMenuObserverService.onCotisationStatus()
        .subscribe((status)=>{
            this.cotisation = status
        });
    }
    
    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {
        this.translationLoader.loadTranslations(english, french);
        // Subscribe to the config changes
        this._fuseConfigService.config
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((settings) => {
                this.horizontalNavbar = settings.layout.navbar.position === 'top';
                this.rightNavbar = settings.layout.navbar.position === 'right';
                this.hiddenNavbar = settings.layout.navbar.hidden === true;
            });

        // Set the selected language from default languages
        this.selectedLanguage = _.find(this.languages, { id: this._translateService.currentLang });
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------


    /**
     * Toggle sidebar open
     *
     * @param key
     */
    toggleSidebarOpen(key): void {
        this._fuseSidebarService.getSidebar(key).toggleOpen();
    }

    /**
     * Search
     *
     * @param value
     */
    search(value): void {
        // Do your search here...
    }

    /**
     * Set the language
     *
     * @param lang
     */
    setLanguage(lang): void {
        // Set the selected language for the toolbar
        this.selectedLanguage = lang;
        // Use the selected language for translations
        this._translateService.use(lang.id);
    }

    onLogout(): void {
        if(this.authenticationService.hasRole("ROLE_TRAINEE") || this.authenticationService.hasRole("external"))
        this.router.navigate(['/auth/trainee/login']);
        else
        this.router.navigate(['/auth/login']);
        this.authenticationService.logout();

    }

    composeMail():void{
        let title1 = "";
        let title2 = "";
        this._translateService.get('NAVBAR.OPERATIONCANCELED').subscribe((text: string) => { title1 = text })
        this._translateService.get('NAVBAR.CLOSE').subscribe((text: string) => { title2 = text })
        this.dialogRef = this._matDialog.open(SendMailComponent, {
            panelClass: 'app-send-mail',
            width: '75%',
            height: '85%',
        });
        this.dialogRef.afterClosed().subscribe((result: boolean) => {
            if (!result) {
                this._snackBar.open(title1, title2, {
                    duration: 4000,
                });
            }
        });
    }

    getListNotifications():void{
        this.notificationServices.GetListNotifications(this.user.publicId)
        .subscribe((data)=>{
            this.notifications = data;
            this.notifications.forEach((element)=>{
                element.extraInfo = Math.abs(element.extraInfo)
            })
        })
    }

    hasRole(role: string): boolean {
        return this.authenticationService.hasRole(role);
    }

    
    navigateTo(notification: Notification, index: number): void{
        if(notification.status == 'NOTCONSULTED' && notification?.publicId)
        this.notificationServices.changeNotificationStatus(notification.publicId).subscribe(()=>{
            this.notifications[index].status = 'CONSULTED';
        })
        switch (notification.typeEntity) {
            case 'INSURANCE':
                this.router.navigate(['/profile/insurance']);
                break;
            case 'COTISATION':
                this.router.navigate(['/profile/cotisation']);
                break;
            case 'USER':
                this.router.navigate(['/profile/users']);
                break;
            case 'COMPANY':
                this.router.navigate(['/profile/company']);
                break;
        
            default:
                break;
        }
    }

    calcDays(dateNotification): string{
        // Parse the date strings as Date objects
        const startDate = new Date(dateNotification);
        const endDate = new Date();

        // Calculate the difference between the two dates in milliseconds
        const timeDiff = endDate.getTime() - startDate.getTime();

        // Convert milliseconds to days
        const daysDiff = Math.ceil(timeDiff / (1000 * 3600 * 24));

        // Calculate the number of weeks
        const weeksDiff = Math.floor(daysDiff / 7);

        // Calculate the number of remaining days after weeks

        // Calculate the number of months
        const monthsDiff = Math.floor(weeksDiff / 4);

        // Calculate the number of remaining weeks after months

        // Convert milliseconds to days
        if (monthsDiff >= 1) {
            return `${monthsDiff} mois`;
        } else if (weeksDiff >= 1) {
            return `${weeksDiff} semaines`;
        } else {
            return `${daysDiff} jours`;
        }

    }
}
