<div class="header-mail">
    <label class="header-label">{{ 'SELECTEVENT.NEWMESSAGE' | translate }}</label>
    <button mat-icon-button (click)="matDialogRef.close()" style="float: right;width: 30px;
    height: 30px;">
        <mat-icon class="style-icon-close">close</mat-icon>
    </button>
</div>
<div class="page-layout simple fullwidth">
    <div class="filter-corp" style="z-index: 2;" [ngClass]="{'collapse_filter':collapseFilter}">
        <div class="filter-close-corp-bubble" (click)="toggleFilterCorp()">
            <div class="close-element">
                <mat-icon>close</mat-icon>
            </div>
        </div>
        <div class="filter-elements">
            <div class="p-24">
                <div class="container" style="overflow: scroll">
                    <div class="form-btn">
                        <button type="button" class="btn-load" (click)="reset()"
                            data-title="Réinitiliser le filter">
                            <mat-icon>settings_backup_restore</mat-icon>
                        </button>
                        <button class="mat-focus-indicator cta-button mat-raised-button mat-button-base mat-accent"
                            mat-flat-button color="primary" style="background-color: #2a96ff;" type="button" class="button" (click)=getListUsers()>
                            <mat-icon style="color: white">filter_list</mat-icon>
                            {{ 'SELECTEVENT.FILTER' | translate }}
                        </button>
                    </div>
                    <div class="scrolling">
                        <mat-form-field class="form-element" appearance="outline">
                            <mat-label>{{ 'SELECTEVENT.NINSCRIT' | translate }}</mat-label>
                            <input matInput type="text" name="cardNumber" [(ngModel)]="filter.cardNumber">
                        </mat-form-field>

                        <mat-form-field class="form-element" appearance="outline">
                            <mat-label>{{ 'SELECTEVENT.NOM' | translate }}</mat-label>
                            <input matInput type="text" name="firstName" [(ngModel)]="filter.firstName">
                        </mat-form-field>

                        <mat-form-field class="form-element" appearance="outline">
                            <mat-label>{{ 'SELECTEVENT.PRENOM' | translate }}</mat-label>
                            <input matInput type="text" name="lastName" [(ngModel)]="filter.lastName">
                        </mat-form-field>

                        <mat-form-field class="form-element" appearance="outline">
                            <mat-label>{{ 'SELECTEVENT.CIN' | translate }}</mat-label>
                            <input matInput type="text" name="cin" [(ngModel)]="filter.cin">
                        </mat-form-field>

                        <mat-form-field class="form-element" appearance="outline">
                            <mat-label>{{ 'SELECTEVENT.EMAIL' | translate }}</mat-label>
                            <input matInput type="text" name="email" [(ngModel)]="filter.email">
                        </mat-form-field>

                        <mat-form-field class="form-element" appearance="outline">
                            <mat-label>{{ 'SELECTEVENT.TELEPHONE' | translate }}</mat-label>
                            <input matInput type="text" name="phone" [(ngModel)]="filter.phone">
                        </mat-form-field>

                        <mat-form-field appearance="outline" class="form-element">
                            <mat-date-range-input [rangePicker]="picker">
                                <input matStartDate [(ngModel)]="start" placeholder=" {{ 'SELECTEVENT.INSCRITDU' | translate }}"/>
                                <input matEndDate [(ngModel)]="end" placeholder="{{ 'SELECTEVENT.TO' | translate }}"/>

                            </mat-date-range-input>
                            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                            <mat-date-range-picker #picker></mat-date-range-picker>
                        </mat-form-field>

                        <mat-form-field class="form-element" appearance="outline">
                            <mat-label>{{ 'SELECTEVENT.GENRE' | translate }}</mat-label>
                            <mat-select [(ngModel)]="filter.gender" name="gender">
                                <mat-option value="">{{ 'SELECTEVENT.TOUT' | translate }}</mat-option>
                                <mat-option value="M"> {{ 'SELECTEVENT.MALE' | translate }}</mat-option>
                                <mat-option value="F"> {{ 'SELECTEVENT.FEMALE' | translate }}</mat-option>
                            </mat-select>
                        </mat-form-field>

                        <mat-form-field class="form-element" appearance="outline">
                            <mat-label>{{ 'SELECTEVENT.COMPTE' | translate }}</mat-label>
                            <mat-select [(ngModel)]="filter.status" name="stat">
                                <mat-option value="">{{ 'SELECTEVENT.TOUT' | translate }}</mat-option>
                                <mat-option value="true">{{ 'SELECTEVENT.ACTIVE' | translate }}</mat-option>
                                <mat-option value="false"> {{ 'SELECTEVENT.DEACTIVE' | translate }}</mat-option>
                            </mat-select>
                        </mat-form-field>

                        <mat-form-field class="form-element" appearance="outline">
                            <mat-label>{{ 'SELECTEVENT.CONTRIBUTION' | translate }}</mat-label>
                            <mat-select [(ngModel)]="filter.contribution" name="sold">
                                <mat-option value="">{{ 'SELECTEVENT.TOUT' | translate }}</mat-option>
                                <mat-option value="true">{{ 'SELECTEVENT.AJOUR' | translate }}</mat-option>
                                <mat-option value="false">{{ 'SELECTEVENT.NOAJOUR' | translate }}</mat-option>
                            </mat-select>
                        </mat-form-field>

                        <mat-accordion>
                            <mat-expansion-panel>
                                <mat-expansion-panel-header>
                                    <mat-panel-title>
                                        <mat-icon> location_on</mat-icon>
                                        <span> {{ 'SELECTEVENT.GOVERNORAT' | translate }}</span>
                                    </mat-panel-title>
                                </mat-expansion-panel-header>
                                <ng-container *ngFor="let ville of listVille">
                                    <div class="gov">
                                        <label style=" width: 120px; padding: 10px;display: inline-block;">{{ville}}
                                            :</label>
                                        <label class="switch">
                                            <input type="checkbox" id='goverment' value="{{ville}}"
                                                (change)="getVilleFilter($event);">
                                            <span class="slider round"></span>
                                        </label>
                                    </div>
                                </ng-container>
                            </mat-expansion-panel>
                        </mat-accordion>
                    </div>
                </div>


            </div>
        </div>
    </div>

    <div class="content p-24" style="overflow: scroll">

        <div class="card-content">

            <mat-form-field class="form-element" appearance="outline">
                <mat-label>{{ 'SELECTEVENT.SUBJECT' | translate }} </mat-label>
                <input matInput type="text" name="subject" [(ngModel)]="newMail.subject">
                <mat-icon matSuffix style="cursor: default;">subject</mat-icon>
            </mat-form-field>

            <label for=""></label>
            <ckeditor [config]="{toolbar: { items: [ 'bold', 'italic', 'link', '|', '|', 'undo', 'redo', '|', 'numberedList', 'bulletedList' ]}}"  
            [editor]="Editor"  [(ngModel)]="newMail.mailContent"></ckeditor>
        </div>

        <div *ngIf="!toggleListUsers" class="col" style="padding-top: 20px;">
            <div class="">
                <button mat-flat-button color="primary" style="background-color: #039be5" type="submit" (click)=openToggleListUsers()>
                    <mat-icon style="color: white; margin-right: 10px;">group_add</mat-icon>
                    {{ 'SELECTEVENT.EMAILTOWHO' | translate }}
                </button>
            </div>
        </div>
        <div *ngIf="toggleListUsers" class="mat-elevation-z8">
            <div class="filter-bubble">
                <div class="row">
                    <div class="col">
                        <div class="filter-bubble-icon" (click)="toggleFilterCorp()">
                            <mat-icon style="cursor: pointer;">tune</mat-icon>
                        </div>
                    </div>
                </div>
            </div>
            <label *ngIf="countSelected > 0" class="count-invited">
                {{countSelected}} {{ 'SELECTEVENT.INVTITEDNBRE' | translate }}
            </label>
            <table mat-table [dataSource]="dataSource" matSort>

                <ng-container matColumnDef="Actions">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                            <div style="display: grid; width: 20px;">
                                <label for="checkedAll" class="container" [ngClass]="{'container-checkbox':selectedIds.length > 0}">
                                    <input class="form-check-input checkbox-visibility" 
                                    id="checkedAll" type="checkbox" (change)="selectAll();" value="" 
                                    name="select">
                                    <span class="checkmark"></span>
                                </label>
                            </div>
                    </th>
                    <td mat-cell *matCellDef="let row">

                        <input class="form-check-input" type="checkbox"
                                    (click)="row.selected = !row.selected;getInvitedList(row.publicId, row)" value="{{row.publicId}}"
                                    id="flexCheckDefault" name="selectUser"
                                    [checked]="(!selectedAll && selectedIds.includes(row.publicId)) ||
                                    (row.selected)">
                        <label class="form-check-label" for="flexCheckDefault">
                        </label>
                    </td>
                </ng-container>
               
                <ng-container matColumnDef="cin">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'SELECTEVENT.CIN' | translate }}</th>
                    <td mat-cell *matCellDef="let row"> {{row.cin}}</td>
                </ng-container>

                <ng-container matColumnDef="nom">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'SELECTEVENT.NOM' | translate }}</th>
                    <td mat-cell *matCellDef="let row"> {{row.firstName}} </td>
                </ng-container>

                <ng-container matColumnDef="prenom">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'SELECTEVENT.PRENOM' | translate }}</th>
                    <td mat-cell *matCellDef="let row"> {{row.lastName}} </td>
                </ng-container>

                <ng-container matColumnDef="email">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'SELECTEVENT.EMAIL' | translate }}</th>
                    <td mat-cell *matCellDef="let row"> {{row.email}} </td>
                </ng-container>

                <ng-container matColumnDef="goverment">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'SELECTEVENT.GOVERNORAT' | translate }}</th>
                    <td mat-cell *matCellDef="let row"> {{row.goverment}} </td>
                </ng-container>

                <ng-container matColumnDef="solde">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'SELECTEVENT.SOLDE' | translate }}</th>

                    <td mat-cell *matCellDef="let row">
                        <mat-icon *ngIf="row.solde >= 0 " style="color: green; cursor: pointer">check_circle
                        </mat-icon>
                        <mat-icon *ngIf="row.solde < 0" style="color: red; cursor: pointer">clear</mat-icon>
                    </td>
                </ng-container>

                <ng-container matColumnDef="status">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'SELECTEVENT.STATUS' | translate }}</th>
                    <td mat-cell *matCellDef="let row">
                        <mat-icon *ngIf="row.status" style="color: green; cursor: pointer">check_circle</mat-icon>
                        <mat-icon *ngIf="!row.status" style="color: red; cursor: pointer">clear</mat-icon>
                    </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

                <!-- Row shown when there is no matching data. -->
                <tr class="mat-row text-center" *matNoDataRow>
                    <td class="mat-cell" colspan="9">{{ 'SELECTEVENT.NODATA' | translate }}</td>
                </tr>
            </table>

            <div class="div">
                <nav aria-label="Page navigation example" *ngIf="totalPages>=0">
                    <div class="text-center">
                        <ul class="pagination">
                            <li (click)="first()" [ngClass]="{'disabled':isFirst()}" class="page-item">
                                <a><span aria-hidden="true">&laquo;</span></a>
                            </li>
                            <li *ngIf="!isFirst()" (click)="prev()">
                                <a>‹</a>
                            </li>
                            <ng-container *ngFor="let item of [].constructor(3); let i = index">
                                <li *ngIf="i+page-3>0" class="page-item">
                                    <a (click)="changepage(i+page-3)" class="page-link">{{i + page - 3}}</a>
                                </li>
                            </ng-container>
                            <ng-container *ngFor="let item of [].constructor(3); let i = index">
                                <li *ngIf="i+page<totalPages" class="page-item">
                                    <a (click)="changepage(i+page)" [ngClass]="{'active-page':page==(i+page)}"
                                        class="page-link">
                                        {{i + page}}
                                    </a>
                                </li>
                            </ng-container>
                            <li *ngIf="totalPages>3"><a>...</a></li>
                            <li class="page-item">
                                <a *ngIf="totalPages>0" (click)="changepage(totalPages)" class="page-link"
                                    [ngClass]="{'active-page':page==totalPages}">{{totalPages}}</a>
                                <a *ngIf="totalPages==0" (click)="changepage(totalPages)" class="page-link"
                                    [ngClass]="{'active-page':page==totalPages+1}">{{totalPages + 1}}</a>
                            </li>
                            <li *ngIf="!isLast() && totalPages>0" (click)="next()" class="page-item">
                                <a class="page-link">›</a>
                            </li>
                            <li (click)="last()" *ngIf="totalPages>0" [ngClass]="{'disabled':isLast()}"
                                class="page-item">
                                <a class="page-link-symbol">
                                    <span aria-hidden="true">&raquo;</span>
                                </a>
                            </li>
                        </ul>
                    </div>
                </nav>
            </div>
        </div>

        <div class="col text-center" style="padding: 40px;">
            <div class="">
                <button mat-flat-button color="primary" style="background-color: #039be5" type="submit" (click)=SendMail()>
                    <mat-icon style="color: white">send</mat-icon>
                    {{ 'SELECTEVENT.ENVOYE' | translate }}
                </button>
            </div>
        </div>

    </div>
</div>
